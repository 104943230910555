var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('b-card',{attrs:{"title":_vm.$t('invoices.unissued_invoices')}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('general.date_from')}},[_c('date-picker',{attrs:{"locale":_vm.currentLocale,"first-day-of-week":2,"max-date":_vm.dateTo,"disabled-dates":"","is24hr":"","model-config":_vm.modelConfig,"masks":{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] },"is-required":true},on:{"input":_vm.trackDateChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}]),model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('general.date_to')}},[_c('date-picker',{attrs:{"locale":_vm.currentLocale,"first-day-of-week":2,"min-date":_vm.dateFrom,"model-config":_vm.modelConfig,"disabled-dates":"","is24hr":"","masks":{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] },"is-required":true},on:{"input":_vm.trackDateChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}]),model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1)],1),_c('InvoiceStatistics',{attrs:{"date-from":_vm.dateFrom,"date-to":_vm.dateTo,"invoice-type":"unissued"}}),_c('b-card',{staticClass:"my-1 d-flex align-center"},[(!this.showLoader)?_c('CustomTable',{ref:"unissuedInvoicesTable",attrs:{"additional_filters":_vm.filters,"fields":_vm.computedColumnDefs,"api-url":"/api/client/v1/invoices/"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('BButton',{staticStyle:{"min-width":"120px","padding":"5px 10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.previewBill(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('general.view_bill'))+" ")])]}}],null,false,2171364894)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }