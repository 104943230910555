<template>
    <b-overlay :show="showLoader">
        <b-card :title="$t('invoices.unissued_invoices')">
            <b-row>
                <b-col>
                    <b-form-group :label="$t('general.date_from')">
                        <date-picker :locale="currentLocale" :first-day-of-week="2" @input="trackDateChange" :max-date='dateTo' disabled-dates is24hr v-model="dateFrom" :model-config="modelConfig" :masks="{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] }" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="inputValue"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('general.date_to')">
                        <date-picker :locale="currentLocale" :first-day-of-week="2" @input="trackDateChange" :min-date="dateFrom" :model-config="modelConfig" disabled-dates is24hr v-model="dateTo" :masks="{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] }" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="inputValue"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <InvoiceStatistics :date-from="dateFrom" :date-to="dateTo" invoice-type="unissued"></InvoiceStatistics>
        <b-card class="my-1 d-flex align-center">
            <CustomTable  ref="unissuedInvoicesTable" v-if="!this.showLoader" :additional_filters="filters" :fields="computedColumnDefs" api-url="/api/client/v1/invoices/">
                <template #actions="{item}">
                    <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="previewBill(item.id)">
                        {{$t('general.view_bill')}}
                    </BButton>
                </template>
            </CustomTable>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BButton, BCard, BCol, BFormGroup, BOverlay, BRow} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {DatePicker} from 'v-calendar'
    import InvoiceStatistics from '@/views/Invoices/Issued/components/InvoiceStatistics.vue'
    import {Formaters} from '@/libs/formaters'
    import CustomTable from '@/components/CustomTable.vue'

    export default {
        components:{
            BButton,
            CustomTable,
            BFormGroup,
            InvoiceStatistics,
            BRow,
            BCol,
            BCard,
            BOverlay,
            DatePicker},
        computed: {
            computedColumnDefs() {
                return [
                    {
                        key: 'bill_id',
                        label: this.$t('table_fields.uuid'),
                        filter: true,
                        filterKey: 'joined_invoice_identifier.w',
                        filterType: 'input',
                        formatterItem: Formaters.formatInvoiceId,
                        permissions: true,
                        minWidth: 100
                    },
                    {
                        key: 'total_price',
                        label: this.$t('table_fields.total_price'),
                        filter: true,
                        filterType: 'input',
                        formatter: Formaters.formatPrice,
                        filterKey: 'total_price.w',
                        filterFormatter: Formaters.formatNumberForFilter,
                        permissions: true,
                        minWidth: 150
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('table_fields.date'),
                        filter: false,
                        formatter: Formaters.formatDateOnly,
                        permissions: true,
                        minWidth: 150
                    },
                    {
                        key: 'buyer.name',
                        label: this.$t('table_fields.buyer'),
                        filter: true,
                        filterType: 'select',
                        filterParams: this.buyers,
                        filterParamsName: 'name',
                        filterKey: 'buyer.id',
                        reduce: ele => ele.id,
                        permissions: true,
                        minWidth: 300,
                        maxWidth: 300
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ]
            },
            currentLocale() {
                return this.$store.getters['user/current_locale']
            }
        },
        data() {
            return {
                filters: {},
                buyers: [],
                showLoader: false,
                dateFrom: new Date(new Date().getFullYear(), 0, 1).toISOString(),
                dateTo: new Date(new Date().getFullYear(), 11, 31).toISOString(),
                modelConfig: {
                    type: 'string',
                    mask: 'iso',
                    timeAdjust: '00:00'
                }
            }
        },
        methods:{
            previewBill(id) {
                this.$router.push({name: 'preview_bill', params:{bill_id: id}})
            },
            getBuyerName(value) {
                if (value) {
                    const buyer = this.buyers.find(ele => ele.id === value)
                    if (buyer) {
                        return buyer.name
                    }
                }

                return '/'
            },
            getInvoiceId(item) {
                if (!item) {
                    return '/'
                }

                if (item.custom_numbering_id && item.custom_numbering_id !== '') return `${item.custom_numbering_id}-${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`
                else return `${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`
            },
            async edit(params) {
                try {
                    this.showLoader = true
                    
                    const editObject = {object_data:{[params.column.colId]: parseInt(params.newValue, 10)}}
                    await this.$http.patch(`/api/client/v1/invoices/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/buyers/')
                    this.buyers = response.data ?? []

                    const indexBuyers = this.computedColumnDefs.findIndex(ele => ele.key === 'buyer.name')

                    if (indexBuyers >= 0) {
                        this.computedColumnDefs[indexBuyers].filterParams = this.buyers
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async trackDateChange() {
                if (!this.filters) {
                    this.filters = {}
                }

                const startTime = this.$dayjs(this.dateFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                const endTime = this.$dayjs(this.dateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

                this.filters['timestamp'] = {
                    type: 'date',
                    value: {
                        start: startTime,
                        end: endTime
                    }
                }


                this.setTableFiltersToURL()

                if (this.$refs.unissuedInvoicesTable) {
                    this.$refs.unissuedInvoicesTable.reloadData()
                }
            },
            setTableFiltersToURL() {
                if (this.filters === {} || !this.filters) {
                    return
                }

                const payloadData = this.filters

                const payload = Buffer.from(JSON.stringify(payloadData)).toString('base64')
                let payloadOld = ''
                if (this.$route.query && this.$route.query['filters']) {
                    payloadOld = this.$route.query['filters']
                }

                const type = (typeof (payloadOld))
                if (type === 'string' && payload !== payloadOld) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, ['filters']: payload } })
                } else if (type === 'object' && payload !== payloadOld[0]) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, ['filters']: payload } })
                }
            },
            readTableFiltersFromURL() {
                if (!this.$route.query || !this.$route.query['filters']) {
                    this.filters = {}
                    return
                }

                // get base64 date from query parameter and parse it
                const payload = this.$route.query['filters']
                const data = JSON.parse(atob(Buffer.from(payload).toString()))

                if (this.filters) {
                    this.filters = data

                    if (this.filters && this.filters['timestamp']) {
                        this.dateFrom = this.filters['timestamp'].value.start
                        this.dateTo = this.filters['timestamp'].value.end
                    }
                }
            }
        },
        async beforeMount() {
            await this.readTableFiltersFromURL()
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>