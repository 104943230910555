import Vue from 'vue'

export const Formaters = {
    formatNumberOfProducts(item) {
        return item.length
    },
    formatPrice(item) {
        return `${Vue.prototype.$numberRounding(item)  }€`
    },
    formatDate(item) {
        return Vue.prototype.dayjs(item).format('DD.MM.YYYY HH:mm:ss')
    },
    formatDateOnly(item) {
        return Vue.prototype.dayjs(item).format('DD.MM.YYYY')
    },
    formatInvoiceId(item) {
        if (!item) {
            return '/'
        }

        if (item.custom_numbering_id && item.custom_numbering_id !== '') return `${item.custom_numbering_id}-${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`
        else return `${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`
    },
    formatPaymentType(item) {
        if (!item || item.length === 0) {
            return '/'
        }

        if (item.length >= 2) {
            return 'Več načinov plačila'
        }

        return item[0].name
    },
    formatNumberForFilter(item) {
        if (item.includes('.')) {
            const tmp = item.split('.')
            let numberOfDecimals = 0
            if (tmp.length === 2) {
                numberOfDecimals = tmp[1].length
            }

            const numberToMultiply = Math.pow(10, numberOfDecimals)

            return  Number(Number(item) * numberToMultiply).toFixed(0)
        }

        return Number(item)
    }
}
