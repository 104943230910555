<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card
                no-body
                class="card-statistics"
            >
                <b-card-header>
                    <b-card-title>{{$t('graphs.statistics')}}</b-card-title>
                    <b-card-text class="font-small-2">
                        {{$t('graphs.last_updated')}}: {{lastUpdatedFormated}}
                    </b-card-text>
                </b-card-header>

                <b-card-body class="statistics-body">
                    <b-row :cols="columnSizes.xs" :cols-sm="columnSizes.sm" :cols-md="columnSizes.md" :cols-lg="columnSizes.lg" >
                        <b-col
                            v-for="item in statisticsItems"
                            :key="item.key"
                            class="my-1"
                        >
                            <div class="d-flex justify-content-start align-items-center">

                                <b-avatar
                                    size="48"
                                    :variant="item.color"
                                    class="mr-1"
                                >
                                    <feather-icon
                                        v-if="item.iconType === 'feather'"
                                        size="24"
                                        :icon="item.icon"
                                    />
                                    <fa
                                        v-else-if="item.iconType === 'fa'"
                                        size="2x"
                                        :icon="item.icon" />
                                    <img alt="icon" v-else-if="item.iconType = 'custom'" :src="item.icon" />
                                </b-avatar>
                                <div >
                                    <h4 class="font-weight-bolder mb-0">
                                        <span v-if="item.key === 'unpaid_invoices_total' || item.key === 'paid_invoices_total' || item.key === 'invoices_in_preparation_total' || item.key === 'invoices_total'">
                                            {{ $numberRounding(item.title, 2) }} €
                                        </span>
                                        <span v-else>
                                            {{ item.title }}
                                        </span>
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ $t(item.subtitle) }}
                                    </b-card-text>
                                </div>
                            </div>

                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {
        BAvatar, BCard, BCardBody,
        BCardHeader,
        BCardText, BCardTitle, BCol, BOverlay, BRow

    } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BCardText,
            BCol,
            // BMediaBody,
            BCardHeader,
            BCardTitle,
            BAvatar,
            BRow,
            // BMediaAside,
            // BMedia,
            BCard,
            BCardBody,
            BOverlay
        },

        props: {
            invoiceType: {
                type: String
            },
            dateFrom:{
                type: String
            },
            dateTo:{
                type: String
            }
        },

        data() {
            return {
                statisticsItemsData: [],
                statisticsItems: [
                    {
                        iconType: 'feather',
                        icon: 'UserIcon',
                        color: 'light-primary',
                        title: '',
                        subtitle: 'invoice_stats.users',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'buyers_with_open_invoices'
                    },
                    {
                        iconType: 'feather',
                        icon: 'HashIcon',
                        color: 'light-danger',
                        title: '',
                        subtitle: 'invoice_stats.num_unpaid',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'number_of_unpaid_invoices'
                    },
                    {
                        iconType: 'fa',
                        icon: 'euro-sign',
                        color: 'light-danger',
                        title: '',
                        subtitle: 'invoice_stats.unpaid_total',
                        customClass: 'mb-2 mb-sm-0',
                        key: 'unpaid_invoices_total'
                    },
                    {
                        iconType: 'fa',
                        icon: 'euro-sign',
                        color: 'light-success',
                        title: '',
                        subtitle: 'invoice_stats.paid_total',
                        customClass: '',
                        key: 'paid_invoices_total'
                    },
                    {
                        iconType: 'fa',
                        icon: 'euro-sign',
                        color: 'light-info',
                        title: '',
                        subtitle: 'invoice_stats.in_preparation_total',
                        customClass: '',
                        key: 'invoices_in_preparation_total'
                    },
                    {
                        iconType: 'fa',
                        icon: 'euro-sign',
                        color: 'light-warning',
                        title: '',
                        subtitle: 'invoice_stats.invoices_total',
                        customClass: '',
                        key: 'invoices_total'
                    }
                ],
                statisticsItemsUnissued: [
                    {
                        iconType: 'feather',
                        icon: 'UserIcon',
                        color: 'light-primary',
                        title: '',
                        subtitle: 'invoice_stats.users',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'buyers_with_open_invoices'
                    },
                    {
                        iconType: 'feather',
                        icon: 'HashIcon',
                        color: 'light-info',
                        title: '',
                        subtitle: 'invoice_stats.num_all',
                        customClass: 'mb-2 mb-sm-0',
                        key: 'total_number_of_invoices'
                    },                    {
                        iconType: 'fa',
                        icon: 'euro-sign',
                        color: 'light-info',
                        title: '',
                        subtitle: 'invoice_stats.all_total',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'invoices_total'
                    }
                ],
                lastUpdated: new Date(),
                dataLoaded: false,
                columnSizes: {
                    'xs': this.invoiceType === 'issued' ? 1 : 1,
                    'sm': this.invoiceType === 'issued' ? 2 : 2,
                    'md': this.invoiceType === 'issued' ? 3 : 3,
                    'lg': this.invoiceType === 'issued' ? 6 : 3
                }
            }
        },

        computed: {
            lastUpdatedFormated() {
                return this.dayjs(this.lastUpdated).format('HH:mm')
            }
        },

        methods: {
            async loadData() {
                this.dataLoaded = false
                try {
                    const response =  await this.$http.post(`/api/client/v1/invoices/${this.invoiceType}/stats`, {'start': this.dateFrom, 'end': this.dateTo})
                    this.statisticsItemsData = response.data ?? {}
                    this.statisticsItems = this.statisticsItems.map((item) => {
                        return {
                            ...item,
                            title: this.statisticsItemsData[item.key]
                        }
                    })
                    this.lastUpdated = new Date()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.dataLoaded = true
                }
            }

        },
        async mounted() {
            if (this.invoiceType === 'unissued') {
                this.statisticsItems = this.statisticsItemsUnissued
            }
            await this.loadData()
        },
        watch: {
            dateFrom: {
                handler: 'loadData',
                deep: true
            },
            dateTo: {
                handler: 'loadData',
                deep: true
            }
        }
    }
</script>